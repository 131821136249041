* {
  background-color: rgb(134, 135, 247);
  font-family: "Press Start 2P", cursive;
  color: #ffffff;
}

/* body {
  align-self: center;
} */

.canvas-container {
  width: 100%;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-self: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  /* background-color: green; */
  /* margin-top: 5%; */
  /* align-content: center; */

  /* justify-self: center; */
}

.flexbox {
  margin: 1%;
  /* border: 3px solid #ffffff;
  background-color: blue; */
}

.row1 {
  text-align: center;
  margin-top:0px;
  /* font-size: 10px; */
  /* border-color: #ffffff;
  border-style: solid; */
}

.row2 {
  /* min-height: auto;
  width: 100%;
  flex-shrink: 0;
  align-self: center;
  display: flex; */
  margin-top:-20px;
  text-align: center;
  border-color: #ffffff;
  border-style: solid;
}

.row3 {
  text-align: center;
  /* font-size: 10px; */
  /* border-color: #ffffff;
  border-style: solid; */
}

a {
  text-decoration: none;
}